import React from 'react'

import Layout from '../components/layout'
import Listing from '../components/listing'

const IndexPage = ({location}) => (
  <Layout location={location}>
    <h2>Articles</h2>
    <p>This is what you build in this course: <a href="https://www.leveluptutorials.com/tutorials/pro-gatsby-2">www.leveluptutorials.com/tutorials/pro-gatsby-2</a></p>
    <Listing />
  </Layout>
)

export default IndexPage
